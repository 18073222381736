<template>
  <b-modal
    id="createVehicle"
    title="Crear vehículo"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col>
        <div class="form-group">
          <label>Placa</label>
          <input
            v-model="form.placa"
            type="text"
            class="form-control form-control-sm"
            placeholder="Código"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Marca</label>
          <select class="form-control form-control-sm" v-model="form.marca">
            <option :value="null">Seleccione</option>
            <option
              :value="item.id_marca"
              v-for="(item, idx) in marcas"
              :key="idx"
            >
              {{ item.descripcion }}
            </option>
          </select>
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Tipo de vehículo</label>
          <Select2 v-model="form.tipo" :options="tvehiculos" />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Modelo</label>
          <input
            v-model="form.modelo"
            min="0"
            type="number"
            class="form-control form-control-sm"
            placeholder="0000"
          />
        </div>
      </b-col>
    </b-row>
    <transition name="fade">
      <b-row v-if="exist.length">
        <b-col>
          <b-col class="dx-viewport mt-3">
            <div class="alert alert-secondary">
              Estos vehículos ya se encuentran registrados
            </div>
            <DxDataGrid
              :data-source="exist"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="true"
              :columnAutoWidth="true"
              :allow-column-resizing="true"
              id="importTable"
            >
              <DxExport :enabled="false" />
              <DxGroupPanel
                :visible="false"
                emptyPanelText="Arrastre una columna aquí para agrupar"
              />
              <DxSearchPanel
                :visible="true"
                :width="240"
                placeholder="Buscar..."
              />
              <DxPaging :page-size="10" />
              <DxPager
                :visible="true"
                infoText="Página {0} de {1} ({2} registros)"
                :allowed-page-sizes="[10, 20, 30, 50]"
                display-mode="full"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
              />
              <DxHeaderFilter :visible="true" />
              <DxFilterRow :visible="true">
                <DxOperationDescriptions
                  equal="Igual a"
                  notEqual="Diferente de"
                  startsWith="Empieza con"
                  endsWith="Termina con"
                  contains="Contiene"
                  between="Entre"
                  notContains="No contiene"
                  lessThanOrEqual="Menor o igual"
                  greaterThanOrEqual="Mayor o igual"
                  lessThan="Menor que"
                  greaterThan="Mayor que"
                />
              </DxFilterRow>
              <DxColumn data-field="placa" caption="Placa" width="130" />
              <DxColumn data-field="marca" caption="Marca" />
              <DxColumn data-field="modelo" caption="Modelo" width="120" />
              <DxColumn data-field="tipo" caption="Tipo" />
            </DxDataGrid>
          </b-col>
        </b-col>
      </b-row>
    </transition>

    <template #modal-footer>
      <div class="form-group">
        <button class="btn btn-sm btn-circle" @click="() => (show = false)">
          <i class="la la-reply"></i> Cancelar
        </button>
        <button class="btn btn-sm btn-success" @click="createTurn()">
          <i class="la la-save"></i>
          {{ loading ? "Cargando..." : "Agregar vehículo" }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
// import axios from "axios";
import Select2 from "v-select2-component";
import toastr from "toastr";
import axios from "axios";
import * as $ from "jquery";
import { mapGetters } from "vuex";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel
} from "devextreme-vue/data-grid";
export default {
  components: {
    Select2,
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel
  },
  data() {
    return {
      show: false,
      loading: false,
      tvehiculos: [],
      marcas: [],
      form: {
        placa: null,
        marca: null,
        modelo: null,
        tipo: null,
      },
      exist: [],
      pageSizes: [5, 10, 15, 30, 50],
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  watch: {
    form() {
      console.log(this.form);
    },
  },
  methods: {
    init() {
      this.show = true;
      this.form = {
        placa: null,
        marca: null,
        modelo: null,
        tipo: -1,
      };
      this.exist = [];
      this.loadMarcas();
      this.loadTipoVehiculo();
      this.removeTabIndex();
    },
    loadMarcas() {
      axios
        .get(`/vehiculo-marcas`)
        .then((res) => {
          this.marcas = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    removeTabIndex() {
      var interval = setInterval(() => {
        $("#createVehicle___BV_modal_content_").attr("tabindex", null);
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
      }, 3000);
    },
    loadTipoVehiculo() {
      axios
        .get(`/vehiculo-tipos`)
        .then((res) => {
          this.tvehiculos = [];
          let tv = [];
          tv.push({
            id: -1,
            text: "Seleccione",
          });
          res.data.map((el) => {
            tv.push({
              id: el.id_tipovehiculo,
              text: el.descripcion.toUpperCase(),
            });
          });
          this.tvehiculos = tv;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    createTurn() {
      if (
        this.form.placa &&
        this.form.marca &&
        this.form.modelo &&
        this.form.tipo !== -1
      ) {
        this.loading = true;
        axios
          .post("/vehiculos", {
            data: [this.form],
            user: this.user.usuario,
          })
          .then((res) => {
            console.log(res.data, "<<<");
            if (res.data.message == "success") {
              toastr.success(res.data.information);
              const vehiculos = res.data.vehiculos;
              if (vehiculos) {
                let exist = vehiculos.filter((f) => f.estado === 0);
                if (exist.length) {
                  this.exist = exist;
                } else {
                  this.$emit("loadVehicles", true);
                  this.show = false;
                }
              }
            } else {
              toastr.info(res.data.information);
            }
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      } else {
        for (const key in this.form) {
          if (!this.form[key] || this.form[key] == -1) {
            toastr.info(`El campo ${key.replace("_", " ")} es obligatorio`);
          }
        }
      }
    },
  },
};
</script>
