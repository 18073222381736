<template>
  <b-row class="mt-5">
    <b-col>
      <b-card title="Vehículos">
        <transition name="fade">
          <b-row >
            <b-col class="dx-viewport">
              <DxDataGrid
                id="TurnsTable"
                :data-source="items"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
                @selection-changed="onSelectionChanged"
              >
                <DxExport :enabled="true" />
                <DxSelection
                  v-if="can('selection-vehicle') && false"
                  :select-all-mode="allMode"
                  :show-check-boxes-mode="checkBoxesMode"
                  mode="multiple"
                />
                <DxGroupPanel
                  :visible="true"
                  emptyPanelText="Arrastre una columna aquí para agrupar"
                />
                <DxSearchPanel
                  :visible="true"
                  :width="240"
                  placeholder="Buscar..."
                />
                <DxPaging :page-size="15" />
                <DxPager
                  :visible="true"
                  infoText="Página {0} de {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxColumn
                  data-field="placa"
                  caption="Placa"
                  alignment="center"
                  width="130"
                />
                <DxColumn
                  data-field="marca.descripcion"
                  caption="Marca"
                  alignment="center"
                />
                <DxColumn
                  data-field="tipo.descripcion"
                  caption="Tipo"
                  alignment="center"
                />
                <DxColumn
                  data-field="brigada"
                  caption="Cód. brigada"
                  alignment="center"
                />
                <DxColumn
                  data-field="tecnico.nombre"
                  caption="Tecnico"
                  alignment="center"
                />

                <DxColumn
                  data-field="aliado"
                  caption="Contrata"
                  alignment="left"
                  v-if="user.roles.name !== 'Contratista'"
                />
                <DxColumn
                  data-field="modelo"
                  caption="Modelo"
                  alignment="center"
                  width="100"
                />
                <DxColumn
                  data-field="estado"
                  caption="Estado"
                  width="180"
                  cell-template="templateStatus"
                />
                <DxColumn
                  data-field="fecha_exp_tecno"
                  caption="TECNO M."
                  data-type="date"
                  format="dd/MM/yyyy"
                />
                <DxColumn
                  data-field="fecha_exp_soat"
                  caption="SOAT"
                  data-type="date"
                  format="dd/MM/yyyy"
                />
                <DxColumn
                  data-field="created_at"
                  caption="Fecha de creación"
                  data-type="date"
                  format="dd/MM/yyyy, HH:mm:ss"
                />
                <DxColumn
                  caption=""
                  cell-template="templateButtons"
                  v-if="can('assign-vehicle')"
                  width="90"
                />
                <DxToolbar>
                  <DxItem location="before" template="createTemplate" />
                  <DxItem location="before" template="loadTemplate" />
                  <DxItem location="before" template="DownloadTemplate" />


                  <DxItem location="after" template="refreshTemplate" />
                  <DxItem name="columnChooserButton" />
                  <DxItem name="exportButton" />
                  <DxItem name="searchPanel" />
                </DxToolbar>
                <template #templateButtons="{ data: cellData }">
                  <div class="text-center">
                    <router-link
                      v-if="can('assign-vehicle')"
                      :to="{
                        name: 'updateVehicle',
                        params: { id: cellData.data.id_vehiculo },
                      }"
                      class="btn btn-icon btn-circle btn-sm"
                    >
                      <b><i class="flaticon-users text-primary"></i></b>
                    </router-link>
                  </div>
                </template>
                <template #createTemplate>
                  <DxButton
                    type="default"
                    styling-mode="contained"
                    icon="plus"
                    text="Agregar"
                    v-if="can('create-vehicle')"
                    @click="$refs.CVH.init()"
                  />
                </template>
                <template #refreshTemplate>
                  <DxButton
                    type="default"
                    styling-mode="outlined"
                    icon="refresh"
                    @click="loadVehicles()"
                  />
                </template>
                <template #loadTemplate>
                  <DxButton
                    type="success"
                    styling-mode="outlined"
                    icon="fa fa-truck"
                    text="Cargar vehículos"
                    @click="$refs.CVM.init()"
                    v-if="can('create-vehicle-massive')"
                  />
                </template>
                <template #DownloadTemplate>
                  <DxButton
                    type="default"
                    icon="download"
                    text="Descargar plantilla"
                    @click="downloadTemplate()"
                    v-if="can('create-vehicle-massive')"
                  />
                </template>
                <template #templateStatus="{ data: cellData }">
                  <div class="mr-3">
                    <i
                      :class="
                        cellData.data.estado == 1
                          ? 'flaticon-interface-10 text-success'
                          : 'flaticon-signs-2'
                      "
                    ></i>
                    {{
                      cellData.data.estado == 1 ? "DISPONIBLE" : "NO DISPONIBLE"
                    }}
                  </div>
                </template>
              </DxDataGrid>
            </b-col>
          </b-row>
          <!-- <b-row v-else>
            <b-col>
              <div v-if="!loading">
                <div class="text-center">
                  <img src="assets/img/empty.png" alt="Empty" />
                  <p class="text-muted">No hay información para mostrar</p>
                </div>
              </div>
              <div class="text-center" v-else>
                <img src="assets/img/loader.gif" alt="Loading..." />
              </div>
            </b-col>
          </b-row> -->
        </transition>
      </b-card>
    </b-col>
    <LoadVehicle ref="CVM" @loadVehicles="loadVehicles" />
    <CreateVehicle ref="CVH" @loadVehicles="loadVehicles" />
  </b-row>
</template>
<style>
.action:hover i {
  opacity: 0.7 !important;
}

#TurnsTable {
  max-height: 700px !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxSelection,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import axios from "axios";
import can from "@/permission";
import { mapGetters } from "vuex";
import toastr from "toastr";
import LoadVehicle from "./LoadVehicle.vue";
import CreateVehicle from "./CreateVehicle.vue";
// import _ from "lodash";
export default {
  name: "user-component",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
    DxSelection,
    DxItem,
    DxToolbar,
    DxButton,
    LoadVehicle,
    CreateVehicle,
  },
  data() {
    return {
      allMode: "allPages",
      checkBoxesMode: "onClick",
      selection: true,
      items: [],
      loading: false,
      pageSizes: [5, 10, 15, 30, 50],
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
      selectedRowsData: [],
      prefix: "",
    };
  },
  mounted() {
    this.loadVehicles();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  filters: {
    performanceTurn(values) {
      var response = values.split("-").join(" y ").split("|").join(" a ");
      return response;
    },
  },
  methods: {
    can: can,
    downloadTemplate() {
      window.open('/assets/templates/CARGUE VEHICULOS.xlsx');
    },
    onSelectionChanged({ selectedRowsData }) {
      if (!this.selectionChangedBySelectBox) {
        this.prefix = null;
      }

      const [data] = selectedRowsData;
      if (data && data.estado.id) {
        this.selectedRowsData = selectedRowsData;
      } else {
        this.selectedRowsData = [];
      }
    },
    approbation(newStatus) {
      const idx = this.selectedRowsData.map((el) => {
        return el.id;
      });
      let data = {
        ids: idx,
        estado_id: newStatus,
        user: this.user.usuario,
      };

      axios
        .post("/turns/approvals", data)
        .then((res) => {
          if (res.data.message == "success") {
            toastr.success(res.data.information);
            this.loadVehicles();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteTurn(id) {
      this.$swal
        .fire({
          title: "Información",
          text: "¿Quiere eliminar el turno?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/turns/${id}`)
              .then((res) => {
                if (res.data.message == "success") {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "success"
                  );
                  this.loadVehicles();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
    loadVehicles() {
      this.selectedRowsData = [];
      this.items = [];
      this.loading = true;
      axios
        .get("/vehiculos")
        .then((res) => {
          this.items = res.data;
          // this.items = _.uniqBy(this.items, "id_vehiculo");
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Document.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
};
</script>
